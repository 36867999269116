
import {
  defineComponent,
  ref,
  unref,
  toRefs,
  watch
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';
import { RuleItem } from 'async-validator';
import { useAdmin, useCreateAdmin, useUpdateAdmin } from '@/composables/api';
import useFormErrors, { validatePassword } from '@/utils/validate';
import { IntBoolean, ResponseError } from '@/services/api';
import { PartialAdmin, AdminStatus } from '@/interfaces/Admin';
import { useI18n } from 'vue-i18n';

const DEFAULT_FORM_VALUES: PartialAdmin & Partial<{ password: string }> = {
  username: '',
  password: '',
  name: '',
  phone: '',
  status: AdminStatus.ENABLE
};

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const adminId = useRoute().params.id as string;
    const page = useRoute().query.page as string;
    const router = useRouter();
    const { t } = useI18n();

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { data, dataUpdatedAt } = useAdmin({ adminId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });
    watch(dataUpdatedAt, () => {
      formValues.value = data.value.data;
    });

    const { isLoading: isCreatedLoading, mutate: create } = useCreateAdmin();
    const { isLoading: isUpdatedLoading, mutate: update } = useUpdateAdmin();
    const { formErrors, bindFormItemError } = useFormErrors();

    const rules = {
      username: [
        {
          required: true,
          trigger: 'blur'
        }
      ],
      password: [
        {
          required: !isEdit.value,
          trigger: ['blur', 'change'],
          validator: (rule: RuleItem, password: string) => {
            if (!rule.required && !password) {
              return true;
            }

            return validatePassword(password);
          }
        }
      ],
      name: [
        {
          required: true,
          trigger: 'blur'
        }
      ],
      status: [
        {
          required: true,
          trigger: 'change'
        }
      ]
    };

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form && form.validate((valid: boolean) => {
        if (valid) {
          if (isEdit.value) {
            update({ adminId, data: formValues.value }, {
              onSuccess() {
                ElMessage.success('success!');

                router.push({
                  name: 'list-admins'
                });
              },

              onError(error: ResponseError) {
                ElMessage.error(error.response?.data.message);
                formErrors.value = error.response?.data.errors;
              }
            });

            return;
          }

          create({ data: formValues.value }, {
            onSuccess() {
              ElMessage.success('success!');

              formValues.value = DEFAULT_FORM_VALUES;
              router.push({
                name: 'list-admins'
              });
            },
            onError(error: ResponseError) {
              ElMessage.error(error.response?.data.message);
              formErrors.value = error.response?.data.errors;
            }
          });
        }
      });
    };

    return {
      page,
      IntBoolean,
      formValues,
      formRef,
      rules,
      AdminStatus,
      isCreatedLoading,
      isUpdatedLoading,
      submitForm,
      formErrors,
      t,
      bindFormItemError
    };
  }
});
